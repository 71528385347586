<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">메뉴권한관리</h1>
    <div class="box-wrap ">
      <div class="box-ct d-flex">
        <div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="dropItems"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="메뉴그룹"
              placeholder="선택"
              v-model="menuGroup"
            >
              <template v-slot:label>
                메뉴그룹
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="dropItems2"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="권한그룹"
              placeholder="선택"
              v-model="atrtGroupId"
            >
              <template v-slot:label>
                권한그룹
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
        </div>
        <div class="ml-auto align-self-center" >
          <v-btn  outlined class="btn-etc2" v-on:click="getListOrNot" id="btnSearch">조회</v-btn>

          <v-btn 
            v-if="USER_AUTH_CHK && this.user_role.userId == 'teletest'"
            outlined
            class="btn-default ml-3"
            @click="showDialog('M810703P01')"
          >권한그룹관리</v-btn>
        </div>

        <!--권한그룹관리 팝업-->  
        <v-dialog max-width="1400" persistent v-if="dialogM810703P01" v-model="dialogM810703P01" :eager="true" content-class="square-modal">
          <dialog-M810703P01
            headerTitle="권한그룹관리"
            p01
            @hide="hideDialog('M810703P01')"
            @submit="submitDialog('M810703P01')"
          >
          </dialog-M810703P01>
        </v-dialog>

      </div>
    </div>
    <div class="d-flex">
      <!--미할당메뉴,할당메뉴 가로-->
      <div class="box-wrap flex-grow-1">
        <h2 class="tit-h2 d-flex">
          <div class="ml-auto">
            <v-btn  outlined class="btn-point ml-2" id="btnSave" v-on:click="common_confirm('저장하시겠습니까?', submitGrid1, '', null, '', 'chk')">저장</v-btn>
          </div>
        </h2>
        <div class="box-ct d-flex">
          <div style="width: 47%">
            <span>미할당메뉴</span>
            <v-data-table
              dense
              height="580px"
              :headers="divGridNoAlloc"
              :items="divGridNoAllocDataText"
              item-key="MENU_ID"
              :item-class="isActiveRow1"
              hide-default-footer
              class="grid-default"
              :page.sync="divGridNoAllocPage"
              @page-count="divGridNoAllocPageCount = $event"
              :items-per-page="itemsPerPage"
              show-select
              fixed-header
              no-data-text="검색된 결과가 없습니다."
              v-model="selectedDivGridNoAllocList"
              v-on:click:row="getGrid1_ListSel"
            >
            </v-data-table>
            <p class="caption text-right">
              <strong class="clr-error">{{ divGridNoAllocDataText.length }}</strong
              >건의 미할당 메뉴가 검색되었습니다.
            </p>
          </div>
          <div class="btn-arrow-wrap-vertical">
            <div class="">
              <v-btn class="btn-arrow" plain small @click="moveLeft">
                <v-icon small class="svg-arrow-left"></v-icon>
              </v-btn>
              <v-btn  class="btn-arrow" plain small @click="moveRight">
                <v-icon small class="svg-arrow-right"></v-icon>
              </v-btn>
            </div>
          </div>
          <div class="flex-grow-1">
            <span>할당메뉴</span>
            <v-data-table
              dense
              :headers="divGridAlloc"
              :items="divGridAllocDataText"
              :items-per-page="itemsPerPage"
              item-key="MENU_ID"
              :item-class="isActiveRow3"
              :page.sync="divGridAllocPage"
              hide-default-footer
              show-select
              fixed-header
              class="grid-default"
              height="580px"
              @page-count="divGridAllocPageCount = $event"
              no-data-text="검색된 결과가 없습니다."
              v-model="selectedDivGridAllocList"
            >
            </v-data-table>
            <p class="caption text-right">
              <strong class="clr-error">{{ divGridAllocDataText.length }}</strong
              >건의 할당 메뉴가 검색되었습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DialogM810703P01 from "../M810703P01.vue";

  export default {
    name: "MENU_COM0200", //name은 'MENU_' + 파일명 조합
    components: {
      DialogM810703P01
    },
    data() {
      return {
        dialogM810703P01: false, //권한그룹관리
        HEADERResult:'',
        DATAResult:'',
        headers:{},
        sendData: {},
        URLData:'',
        atrtGroupId:'',
        menuGroup:'',

        itemsPerPage: -1,

        selectedMenu:'',

        timeStep: "10:10",
        timeStep2: "05:10",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        dates: [
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
        ],

        dropItems:[],
        dropItems2:[],
        divGridNoAlloc: [
          {
            text: "메뉴명",
            value: "MENU_NM",
            align: "left",
            sortable: true,
          },
        ],
        divGridAlloc: [
          {
            text: "권한그룹명",
            value: "ATRT_GROUP_NM",
            align: "center",
            sortable: false,
          },
          {
            text: "메뉴명",
            value: "MENU_NM",
            align: "left",
            sortable: true,
          }
        ],
        divGridNoAllocDataText: [],

        divGridNoAllocPage: 1,
        divGridNoAllocPageCount: 0,
        divGridNoAllocItemsPerPage: 100,
        divGridNoAllocTotalVisible: 10,
        divGridAllocDataText: [],
        divGridAllocPage: 1,
        divGridAllocPageCount: 0,
        divGridAllocItemsPerPage: 100,
        divGridAllocTotalVisible: 10,
        selectedDivGridAllocList:[],
        selectedDivGridNoAllocList:[],
    
        detailData: {},

        USER_AUTH_CHK: false,

      };
    },
    
    methods: {

      //권한그룹 콤보박스
      groupCodeInqire(){
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/menu-author-manage/author-group-code/inqire";
        this.headers["SERVICE"] = "setting.system.menu-author-manage.author-group-code";
        this.headers["METHOD"] = "inqire";
        this.headers["ASYNC"] = false;

        this.result();
      },

      groupCodeInqireCallBack(response){
        let header = response.HEADER;
        let data = response.DATA;
        this.dropItems2 = data;
      },

      //메뉴그룹 콤보박스
      menuGroupInqire(){
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/menu-author-manage/group/inqire";
        this.headers["SERVICE"] = "setting.system.menu-author-manage.group";
        this.headers["METHOD"] = "inqire";
        this.headers["ASYNC"] = false;

        this.sendData['USE_YN']='Y'

        this.result();
      },

      menuGroupInqireCallBack(response){
        let header = response.HEADER;
        let data = response.DATA;
        this.dropItems = data;

        this.groupCodeInqire();
      },

      getListOrNot(){
        if(this.mixin_isEmpty(this.menuGroup)) {
          this.common_alert('메뉴그룹을 선택해주세요.', 'chk');
        }else if(this.mixin_isEmpty(this.atrtGroupId)){
          this.common_alert('권한그룹을 선택해주세요.', 'chk');
        } else{
          this.getList();
        }
      },

      getList(){
        this.getDivGridNoAlloc();//미할당 사용자
      },

      getDivGridNoAlloc(){
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/menu-author-manage/un-asgn-menu/inqire";
        this.headers["METHOD"] = "inqire";
        this.headers["SERVICE"] = "setting.system.menu-author-manage.un-asgn-menu";
        this.headers["ASYNC"] = false;
        this.headers["GRID_ID"] = "divGridNoAlloc";

        this.sendData= {};
        this.sendData["MENU_GROUP"] = this.menuGroup;
        this.sendData["ATRT_GROUP_ID"] = this.atrtGroupId;
        this.result();
      },

      getDivGridAlloc(){
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/menu-author-manage/asgn-menu/inqire";
        this.headers["SERVICE"] = "setting.system.menu-author-manage.asgn-menu";
        this.headers["METHOD"] = "inqire";
        this.headers["ASYNC"] = false;
        this.headers["GRID_ID"] = "divGridAlloc";

        this.sendData= {};
        this.sendData["MENU_GROUP"] = this.menuGroup;
        this.sendData["ATRT_GROUP_ID"] = this.atrtGroupId;
        this.result();
      },

      getDivGridNoAllocCallBack(response){
        let header = response.HEADER;
        let data = response.DATA;
        this.divGridNoAllocDataText = data;
        let idx = 1;
        let dataText = this.divGridNoAllocDataText;
        for (let i in dataText) {
          this.divGridNoAllocDataText[i]["index"] = idx++;
        }
        this.selectedDivGridNoAllocList = [];
        this.detailData = [];
      },

      getDivGridAllocCallBack(response){
        let header = response.HEADER;
        let data = response.DATA;
        this.divGridAllocDataText = data;
        let idx = 1;
        let dataText = this.divGridAllocDataText;
        for (let i in dataText) {
          this.divGridAllocDataText[i]["index"] = idx++;
        }
        this.selectedDivGridAllocList =[];
        this.selectedMenu = '';
      },

      moveRight() {
        let grid1_selected = this.selectedDivGridNoAllocList;  // 미할당 사용 체크박스
        let grid1 = this.divGridNoAllocDataText;     // 미할당사용자 그리드
        let grid2 = this.divGridAllocDataText;       // 할당사용자 그리드
        if (grid1_selected.length > 0) {
          for(var i in grid1_selected){
            grid1.splice(grid1.indexOf(grid1_selected[i]) , 1);
            grid2.push(grid1_selected[i]);
          }
          this.selectedDivGridNoAllocList = [];
        } else {
          this.common_alert("미할당 메뉴가 선택되지 않았습니다.","chk");
        }
      },

      moveLeft() {
        let grid2_selected = this.selectedDivGridAllocList;   // 할당 사용 체크박스
        let grid1 = this.divGridNoAllocDataText;     // 미할당사용자 그리드
        let grid2 = this.divGridAllocDataText;       // 할당사용자 그리드
        if (grid2_selected.length > 0) {
          for(var i in grid2_selected) {
            grid2.splice(grid2.indexOf(grid2_selected[i]), 1);
            grid1.push(grid2_selected[i]);
          }
          this.selectedDivGridAllocList = [];
        } else {
          this.common_alert("할당 메뉴가 선택되지 않았습니다.","chk");
        }
      },

      submitGrid1() {//저장
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/system/menu-author-manage/regist";
        this.headers["SERVICE"] = "setting.system.menu-author-manage";
        this.headers["METHOD"] = "regist";
        this.headers["ASYNC"] = false;
        this.headers["SUBMIT"] ="getlist";
        let submitList = [];
        let headersObj = {
          DATA_FLAG:"D",
          MENU_GROUP:this.menuGroup,
          ATRT_GROUP_ID:this.atrtGroupId,
        }
        submitList.push(headersObj);
        for(let i in this.divGridAllocDataText){
          let obj = {
            divGridAlloc_CHECKBOX:'0',
            LOWRNK_MENU_ID: "",
            ATRT_GROUP_ID: this.atrtGroupId,
            ATRT_GROUP_NM: this.divGridAllocDataText[i].ATRT_GROUP_NM,//권한그룹명
            MENU_NM: this.divGridAllocDataText[i].MENU_NM,//메뉴명
            MENU_ID: this.divGridAllocDataText[i].MENU_ID,
            REGR_DEPT_CD: 'x',
            REGR_ID: this.user_id,
            PROC_ID: this.user_id,
            DATA_FLAG:"I",
          };
          submitList.push(obj);
        }
        this.sendData  = {
          list:submitList
        }
        this.result();
      },
      submitGridCallBack(response){//
        this.common_alert("정상처리 되었습니다.");
        this.getList();
      },

      getGrid1_ListSel(item){
        this.detailData = JSON.parse(JSON.stringify(item));
      },
      isActiveRow1(item){
        return item.MENU_ID== this.detailData.MENU_ID? 'active':'';
      },

      isActiveRow3(item){
        return item.MENU_ID== this.selectedMenu.MENU_ID? 'active':'';
      },
      startDate(e) {
        this.dates[0] = e;
      },
      endDate(e) {
        this.dates[1] = e;
      },
      showDialog(type) {
        this[`dialog${type}`] = true;
      },
      hideDialog(type) {
        this[`dialog${type}`] = false;
      },
      submitDialog(type) {
        this[`dialog${type}`] = false;
      },

      async result(){

        let requestData = {
          headers: this.headers,
          sendData: this.sendData,
        };

        requestData.headers["URL"] = this.URLData;

        let response = await this.common_postCall(requestData);

        if(!this.mixin_isEmpty(response.HEADER.ERROR_FLAG) && response.HEADER.ERROR_FLAG) {
          let msg = '';
          if(!this.mixin_isEmpty(response.HEADER.ERROR_MSG)) {
            msg = response.HEADER.ERROR_MSG;
          }else{
            msg = '관리자에게 문의해주세요.';
          }
          this.common_alert(msg, 'error');
          return;
        }else{
          if(response.HEADER.METHOD == "inqire" &&response.HEADER.GRID_ID =="divGridNoAlloc"){
            this.getDivGridNoAllocCallBack(response);
            this.getDivGridAlloc();//할당사용자
          }else if(response.HEADER.METHOD == "inqire" &&response.HEADER.GRID_ID =="divGridAlloc") {
            this.getDivGridAllocCallBack(response);
          }else if(response.HEADER.METHOD == "regist" &&response.HEADER.SUBMIT == "getlist") {
            this.submitGridCallBack(response);
          }else if(response.HEADER.METHOD == "inqire" &&response.HEADER.SERVICE =="setting.system.menu-author-manage.group") {
            this.menuGroupInqireCallBack(response);
          }else if(response.HEADER.METHOD == "inqire" &&response.HEADER.SERVICE =="setting.system.menu-author-manage.author-group-code") {
            this.groupCodeInqireCallBack(response);
          }
        }
      }
    },

    mounted() {

      if(this.user_role.userGroupCd == "SYSTEMMANAGER") {
        this.USER_AUTH_CHK = true;
      }else{
        this.USER_AUTH_CHK = false;
      }

      this.menuGroupInqire();
    },

    computed: {
      dateRangeText() {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + " ~ " + newEndDate;
      },

      initHeaders(){//getDivGridNoAlloc
        return this.headers = {
          "SERVICE": 'setting.system.menu-author-manage.un-asgn-menu',
          "METHOD" : "",
          "TYPE" :'BIZ_SERVICE',
          "GRID_ID":"",

        };
      },
    },
  };
</script>

<style>

</style>
